<template>
  <div id="main">
    <Card>
      <Row class="operation">
        <Button @click="addCode" type="primary">添加授权码</Button>
      </Row>
      <Table :loading="loading" border :columns="columns" :data="codelist" ref="table" sortable="custom"  ></Table>
      <Row type="flex" justify="end" class="mt_10">
        <Page
          :page-size="seachlist.limit"
          :total="total"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          size="small"
          show-total
          show-elevator
        ></Page>
      </Row>
    </Card>
    <Modal  v-model="roleModalVisibles" :mask-closable="false" :width="500">
      <div id="code">
        <canvas id="canvas"></canvas>
      </div>
    </Modal>
    <Modal :title="modalTitle" v-model="roleModalVisible"  @on-cancel="cancel" :width="500">
      <el-form :model="roleForm" ref="roleForm">
        <el-form-item label="授权码：" :label-width="formLabelWidth">
          <el-input v-model="roleForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-show="modalTitle=='添加授权码'" label="可使用次数：" :label-width="formLabelWidth">
          <el-input v-model="roleForm.scan_number"  autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-show="modalTitle=='授权码编辑'" label="添加可使用次数：" :label-width="formLabelWidth">
          <el-input v-model="roleForm.scan_number"  autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="排序：" :label-width="formLabelWidth" style="margin-bottom:-5px;">
          <el-input v-model="roleForm.sort" autocomplete="off"></el-input>
          <span>*数字越大越靠前</span>
        </el-form-item>
        <el-form-item label="关闭/启用：" :label-width="formLabelWidth" style="margin-bottom:0px;">
          <el-switch v-model="value1"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="submitRole('roleForm')">确 定</el-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import QRCode from "qrcode"; //定义生成授权码组件
QRCode;
export default {
  data() {
    return {
      modalType: 0, // 0 添加 1 编辑
      value1:true,
      formLabelWidth: '150px',
      modalTitle:'',
      roleModalVisible:false,
      roleForm:{
        name:'',
        scan_number:'',
        sort:1,
        is_on:1,
        id:''
      },
      codelist: [],
      seachlist:{
        page:1,
        limit:10,
      },
      roleModalVisibles:false,
      total:0,
      loading:true,
      submitLoading:false,
      columns: [
        {
          title: 'ID',
          key: 'id',
          align: 'center',
          width: 90,
        },
        {
          title: '名称',
          key: 'name',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '排序',
          key: 'sort',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '状态',
          key: 'is_on',
          align: 'center',
          minWidth: 100,
          tooltip: true,
          render: (h, params) => {
            if (params.row.is_on == 1) {
              return h('div', [
                h(
                  'Button',
                  { props: { size: 'small', type: 'success' }, style: {} },
                  '启用'
                ),
              ])
            } else if (params.row.is_on == 0) {
              return h('div', [
                h(
                  'Button',
                  { props: { size: 'small', type: 'error' }, style: {} },
                  '关闭'
                ),
              ])
            }
          },
        },
        {
          title: '可用次数',
          key: 'usable_num',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '已用次数',
          key: 'used_num',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '总次数',
          key: 'scan_number',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '创建时间',
          key: 'create_time',
          width: 220,
          sortable: true,
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          width: 330,
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  props: {
                    size: 'small',
                    type: 'info',
                  },
                  style: {
                    marginRight: '15px',
                  },
                  on: {
                    click: () => {
                      this.edit(params.row)
                    },
                  },
                },
                '编辑'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.remove(params.row)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
    }
  },
  components: {

  },
  methods: {
    formChange(data){
		  let fd = new FormData();
		  for(let i in data){
			  if(data[i]==''){
				  delete data[i]
			  }else{
				  fd.append(i,data[i])
			  }
		  }
		  return fd
	  },
    addCode(){
      this.roleModalVisible=true
      this.modalTitle='添加授权码'
    },
    changePage(v) {
      this.seachlist.page = v;
      this.getQrcodeList();
    },
    // 分页 改变页数
    changePageSize(v) {
      this.seachlist.page = v;
      this.getQrcodeList();
    },
    async getQrcodeList(){
			const{data:res}= await this.$http.get('/getQrcodeList',{params:this.seachlist})
      this.loading = false;
      this.codelist=res.data.list
      for(let i in this.codelist){
            let date = new Date(this.codelist[i].create_time * 1000)
            let Y = date.getFullYear() + '-'
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
            let H = date.getHours() + ':'
            let M2 = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
            let S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
            let createtime=Y + M + D + H + M2 + S

            let dates = new Date(this.codelist[i].update_time * 1000)
            let Ys = dates.getFullYear() + '-'
            let Ms = (dates.getMonth() + 1 < 10 ? '0' + (dates.getMonth() + 1) : dates.getMonth() + 1) + '-'
            let Ds = dates.getDate() < 10 ? '0' + dates.getDate() + ' ' : dates.getDate() + ' '
            let Hs = dates.getHours() + ':'
            let M2s = (dates.getMinutes() < 10 ? '0' + dates.getMinutes() : dates.getMinutes()) + ':'
            let Ss = dates.getSeconds() < 10 ? '0' + dates.getSeconds() : dates.getSeconds()
            let updatetime=Ys + Ms + Ds + Hs + M2s + Ss

            this.codelist[i].usable_num=this.codelist[i].scan_number-this.codelist[i].used_num
            this.codelist[i].update_time=updatetime
            this.codelist[i].create_time=createtime
      }
      this.total=res.data.count
    },
    edit(v){
      console.log(v);
        this.modalTitle='授权码编辑'
        this.roleModalVisible=true
        if(v.is_on==1){
          this.value1=true
        }else{
          this.value1=false
        }
        this.roleForm.name=v.name
        this.roleForm.is_on=v.is_on
        this.roleForm.sort=v.sort
        this.roleForm.id=v.id
    },
    cancel(){
        this.submitLoading = false;
        this.roleModalVisible=false
        this.value1=true
        this.roleForm.name=''
        this.roleForm.sort=''
        this.roleForm.scan_number=''
        this.roleForm.scan_number=''
    },
    remove(v){
      this.$Modal.confirm({
        title: "确认删除",
        content: "您确认要删除授权码" + v.name + " ?",
        loading: true,
        onOk: () => {
          let id={id:v.id}
          this.deleteQrcode(id)
        },
      });
    },
    async deleteQrcode(id){
      let {data:res}=await this.$http.post('/deleteQrcode',this.formChange(id))
      if(res.code==200){
          this.$Modal.remove();
          this.$Message.success("删除成功");
          this.getQrcodeList();
      }else{
        this.$Message.success(res.msg);
      }
    },
    async addQrcode(){
      let {data:res}=await this.$http.post('/addQrcode',this.formChange(this.roleForm))
      if(res.code==200){
        this.$Message.success("操作成功");
        this.submitLoading = false;
        this.roleModalVisible=false
        this.getQrcodeList();
      }else{
        this.$Message.success(res.msg);
      }
    },
    async editQrcode(){
      let {data:res}=await this.$http.post('/editQrcode',this.formChange(this.roleForm))
      if(res.code==200){
        this.$Message.success("操作成功");
        this.submitLoading = false;
        this.roleModalVisible=false
        this.getQrcodeList();
      }else{
        this.$Message.success(res.msg);
      }
    },
    submitRole() {
        if(this.roleForm.name==''){
          alert('授权码名称不能为空')
          return
        }else if(this.roleForm.sort==''){
          alert('排序不能为空')
          return
        }
        this.submitLoading = true;
        if(this.value1==true){
          this.roleForm.is_on=1
        }else{
          this.roleForm.is_on='0'
        }
        if(this.modalTitle=='添加授权码'){
          this.addQrcode()
        }else{
          this.editQrcode()
        }
      },

  },

  mounted() {
    this.getQrcodeList()
  },
}
</script>

<style scoped lang="less">
#code{
  width: 100px;
  height: 100px;
}
/deep/.ivu-row{
  margin-bottom: 10px;
}
</style>
